import React, { useState } from 'react';
import styles from './index.module.css';
import PortfolioOptions from './PortfolioOptions';
import PortfolioList from './PortfolioList';
import PortfolioDetail from './PortfolioDetail';
import useWindowDimensions from '../useWindowDimensions';
import MobilePortfolio from './MobilePortfolio';

const Portfolio = ({ projects, theme }) => {
  const [selectedOption, setSelectedOption] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const { height, width } = useWindowDimensions();

  const selectProject = project => {
    setSelectedProject(project);
  };

  const unselectProject = () => {
    setSelectedProject();
  };

  const selectOption = option => {
    if (option === selectedOption) {
      setSelectedOption(undefined);
    } else {
      setSelectedOption(option);
    }
    setSelectedProject(undefined);
  };

  const webPortfolio = (
    <section className={styles.portfolioSection} id={'portfolio'}>
      <h1 className={'titleSection'}>PORTFÓLIO</h1>
      <PortfolioOptions onClick={selectOption} selected={selectedOption} />
      {selectedProject === undefined ? (
        <PortfolioList
          projects={projects}
          selectProject={selectProject}
          selectedOption={selectedOption}
        />
      ) : (
        <PortfolioDetail
          id={'themePortfolio'}
          unselectProject={unselectProject}
          selectedProject={selectedProject}
        />
      )}
    </section>
  );

  const mobilePortfolio = () => {
    return (
      <MobilePortfolio projects={projects} theme={theme}></MobilePortfolio>
    );
  };

  if (width < 1000) {
    return mobilePortfolio();
  }
  return webPortfolio;
};

export default Portfolio;
