import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import logo from '../../images/logo.svg';
import logoBlack from '../../images/logo_dark.svg';
// import logoWhite from '../../images/logoWhite.png';
import moon from '../../images/inicial/moon.png';
import sun from '../../images/inicial/sun.png';
// import onda from '../../images/inicial/onda.svg';
import ondaLaranja from '../../images/onda-gigante.svg';
import ondaAzul from '../../images/onda-gigante-azul.svg';
import ondaAmarelo from '../../images/onda-gigante-amarelo.svg';
// import { tween } from 'popmotion';
// import { interpolate } from 'flubber';
// import posed from 'react-pose';
import useWindowDimensions from '../useWindowDimensions';
import menu from '../../images/menu.svg';
import menuDark from '../../images/menu_dark.svg';
// import arrowRight from '../../images/arrowRight.svg';
import arrowRightLaranja from '../../images/arrowRightLaranja.svg';

const TesteHeader = ({ state, theme, setTheme, gradient, colorTheme }) => {
  const [pathIndex, setPathIndex] = useState(0);
  const [rectangleHeight, setRectangleHeight] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const { height, width } = useWindowDimensions();

  const changeTheme = () => {
    if (theme == 'dark') {
      document.documentElement.style.setProperty('--main-bg-color', '#F8F8F8');
      document.documentElement.style.setProperty('--text-color', 'black');
      document.documentElement.style.setProperty('--box-color', '#EDEDED');
      document.documentElement.style.setProperty('--mvv-background', '#EDEDED');
      document.documentElement.style.setProperty('--mvv-text', '#D3D3D3');

      setTheme('light');
    } else {
      document.documentElement.style.setProperty('--main-bg-color', '#050c27');
      document.documentElement.style.setProperty('--text-color', 'white');
      document.documentElement.style.setProperty('--box-color', '#1e243d');
      document.documentElement.style.setProperty(
        '--mvv-background',
        'rgba(255, 255, 255, 0.1)'
      );
      document.documentElement.style.setProperty('--mvv-text', '#050C27');

      setTheme('dark');
    }
  };

  const getImgByColorTheme = () => {
    if (colorTheme === 'blue')
      return <img style={{ width: '100%' }} src={ondaAzul}></img>;
    if (colorTheme === 'yellow')
      return <img style={{ width: '100%' }} src={ondaAmarelo}></img>;
    return <img style={{ width: '100%' }} src={ondaLaranja}></img>;
  };

  const scrollToElement = elementToScroll => {
    const yOffset = -100;
    const element = document.getElementById(elementToScroll);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const header = (
    <div className={styles.topHeader}>
      <div className={styles.logo}>
        <img src={logo} className={styles.logoIcon} alt="logo"></img>
      </div>
      <div>
        <ul className={styles.options}>
          <li style={{ marginTop: '10px' }}>
            <a
              className={styles.headerOption}
              onClick={() => scrollToElement('portfolio')}
            >
              PORTFÓLIO
            </a>
          </li>
          <li style={{ marginTop: '10px' }}>
            <a
              className={styles.headerOption}
              onClick={() => scrollToElement('servicos')}
            >
              SERVIÇOS
            </a>
          </li>
          <li style={{ marginTop: '10px' }}>
            <a
              className={styles.headerOption}
              onClick={() => scrollToElement('parceiros')}
            >
              PARCEIROS
            </a>
          </li>
          <li style={{ marginTop: '10px' }}>
            <a
              className={styles.headerOption}
              onClick={() => scrollToElement('footer')}
            >
              FALE CONOSCO
            </a>
          </li>
          <li className={styles.darkWrapper}>
            {theme === 'dark' ? (
              <img
                className={styles.darkThemeSelector}
                src={moon}
                onClick={changeTheme}
              ></img>
            ) : (
              <img
                className={styles.darkThemeSelector}
                src={sun}
                onClick={changeTheme}
              ></img>
            )}
          </li>
        </ul>
        <div className={styles.iconMenuMobile}>
          <li className={styles.darkWrapperMobile}>
            {theme === 'dark' ? (
              <img
                className={styles.darkThemeSelector}
                src={moon}
                onClick={changeTheme}
              ></img>
            ) : (
              <img
                className={styles.darkThemeSelector}
                src={sun}
                onClick={changeTheme}
              ></img>
            )}
          </li>
          <img
            className={styles.menu}
            src={menu}
            alt="menu"
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          ></img>
        </div>
      </div>
    </div>
  );

  const lateralMenu = (
    <div
      onClick={() => setOpenMenu(false)}
      className={styles.menuContainer}
      style={{
        marginRight: openMenu ? '0' : '-100vw',
        backgroundColor: theme === 'light' ? '#EDEDED' : '#1E243D',
      }}
    >
      <div className={styles.headerMobile}>
        {theme === 'light' ? (
          <div className={styles.logoHeader}>
            <img src={logoBlack} alt="logo" style={{ height: '4.5rem' }}></img>
          </div>
        ) : (
          <div className={styles.logoHeader}>
            <img src={logo} alt="logo" style={{ height: '4.5rem' }}></img>
          </div>
        )}
        <img
          className={styles.menu}
          src={theme === 'dark' ? menu : menuDark}
          alt="menu"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        ></img>
      </div>

      <ul>
        <li style={{ marginTop: '8px' }}>
          <img className={styles.headerIcon} src={arrowRightLaranja} />
          <a
            className={styles.headerOption}
            onClick={() => scrollToElement('portfolio')}
          >
            PORTFÓLIO
          </a>
        </li>
        <li style={{ marginTop: '8px' }}>
          <img className={styles.headerIcon} src={arrowRightLaranja} />
          <a
            className={styles.headerOption}
            onClick={() => scrollToElement('servicos')}
          >
            SERVIÇOS
          </a>
        </li>
        <li style={{ marginTop: '8px' }}>
          <img className={styles.headerIcon} src={arrowRightLaranja} />
          <a
            className={styles.headerOption}
            onClick={() => scrollToElement('parceiros')}
          >
            PARCEIROS
          </a>
        </li>
        <li style={{ marginTop: '8px' }}>
          <img className={styles.headerIcon} src={arrowRightLaranja} />
          <a
            className={styles.headerOption}
            onClick={() => scrollToElement('footer')}
          >
            FALE CONOSCO
          </a>
        </li>
      </ul>
      <div></div>
    </div>
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      {header}
      {lateralMenu}
      <div style={{ height: '80vh' }}>
        {getBackgroundOnda(getImgByColorTheme)}
      </div>
    </div>
  );
};
TesteHeader.propTypes = {
  siteTitle: PropTypes.string,
};

TesteHeader.defaultProps = {
  siteTitle: ``,
};

export default TesteHeader;
function getBackgroundOnda(getImgByColorTheme) {
  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{ width: '100%', flex: 1 }}
          className={styles.fillContainer}
        ></div>
        {getImgByColorTheme()}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '80vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div className={styles.title}>
            <h1>O bom design é&nbsp;</h1>
            <h1 className={styles.linguagem}>linguagem</h1>
            <h1>,</h1>
          </div>
          <div className={styles.title}>
            <h1>além do&nbsp;</h1>
            <h1>estilo</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
