import React, { useState } from 'react';
import styles from './index.module.css';
import data from './data';
import useWindowDimensions from '../useWindowDimensions';

import arrowLeft from '../../images/arrowLeft.svg';
import arrowRight from '../../images/arrowRight.svg';

import arrowLeftDark from '../../images/arrowLeftDark.svg';
import arrowRightDark from '../../images/arrowRightDark.svg';

const Mvv = ({ theme }) => {
  const [currentData, setCurrentData] = useState(0);
  const { height, width } = useWindowDimensions();

  const changeData = index => {
    setCurrentData(index);
  };

  if (width < 640) {
    return mobileMvv(currentData, changeData, theme);
  }
  return webMvv(currentData, changeData);
};

const Option = ({ selected, text, onClick }) => {
  return (
    <h1
      className={[styles.option, selected ? styles.selected : undefined].join(
        ' '
      )}
      onClick={onClick}
    >
      {text}
    </h1>
  );
};

function webMvv(currentData, changeData) {
  return (
    <div className={styles.mvv}>
      <section id={'mvv'}>
        <div className={styles.content}>
          <div className={styles.options}>
            <Option
              selected={currentData === 0}
              text={data[0].title}
              onClick={() => {
                changeData(0);
              }}
            />
            <Option
              selected={currentData === 1}
              text={data[1].title}
              onClick={() => {
                changeData(1);
              }}
            />
            <Option
              selected={currentData === 2}
              text={data[2].title}
              onClick={() => {
                changeData(2);
              }}
            />
          </div>
          <div id={'themeMvv'} className={styles.themeMvv}>
            <p
              className={[
                styles.value,
                currentData == 0 ? styles.showvalue : undefined,
                styles.overlay,
              ].join(' ')}
            >
              {data[0].description}
            </p>
            <p
              className={[
                styles.value,
                currentData == 1 ? styles.showvalue : undefined,
                styles.overlay,
              ].join(' ')}
            >
              {data[1].description}
            </p>
            <p
              className={[
                styles.value,
                currentData == 2 ? styles.showvalue : undefined,
                styles.overlay,
              ].join(' ')}
            >
              {data[2].description}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

function mobileMvv(currentData, changeData, theme) {
  return (
    <div className={styles.content}>
      <div className={styles.optionsMobile}>
        <img
          className={currentData > 0 ? '' : styles.arrowHidden}
          src={theme === 'dark' ? arrowLeft : arrowLeftDark}
          alt="esquerda"
          onClick={() => {
            changeData(currentData - 1);
          }}
        ></img>
        <h1 className={styles.selected}>{data[currentData].title}</h1>
        <img
          className={currentData < 2 ? '' : styles.arrowHidden}
          src={theme === 'dark' ? arrowRight : arrowRightDark}
          alt="direita"
          onClick={() => {
            changeData(currentData + 1);
          }}
        ></img>
      </div>
      <div className={styles.valueMobile}>{data[currentData].description}</div>
    </div>
  );
}

export default Mvv;
