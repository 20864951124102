import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Inicial from '../components/inicial';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Mvv from '../components/mvv';
import Portfolio from '../components/portfolio';
import Parceiros from '../components/parceiros';
import Servicos from '../components/servicos';
import Service from '../services/service';
import loadingGif from '../images/loading.gif';

const IndexPage = props => {
  const [state, setState] = useState({
    header: 0,
  });
  const [theme, setTheme] = useState('dark');
  const [colorTheme, setColorTheme] = useState();
  const [projects, setProjects] = useState();
  const [parceiros, setParceiros] = useState();
  const [loading, setLoading] = useState(true);
  const [gradient, setGradient] = useState({
    firstGradient: '#E77D22',
    lastGradient: '#DF392D',
  });

  const service = new Service();

  const changeState = (prop, value) => {
    setState({ ...state, [prop]: value });
  };

  const handleScroll = () => {
    let position;
    if (typeof window !== 'undefined') {
      position = window.pageYOffset;
    }
    if (position < 5) {
      changeState('header', 0);
    } else if (position > 10 && position < 470) {
      changeState('header', 1);
    } else if (position > 470) {
      changeState('header', 2);
    }
  };

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getData = async () => {
    setLoading(true);
    const data = await service.getData();
    setProjects(data.data.projetos);
    setParceiros(data.data.parceiros);
    setLoading(false);
  };

  useEffect(async () => {
    getData();
  }, []);

  const loadingFunc = () => {
    return (
      <div className="loading">
        <img style={{ width: '33%' }} src={loadingGif} />
      </div>
    );
  };

  const changeColorTheme = color => {
    // document.documentElement.style.setProperty('--main-bg-color', '#050c27');

    if (color === 'blue') {
      document.documentElement.style.setProperty('--header-color', '#F55410');
      document.documentElement.style.setProperty('--first-gradient', '#262069');
      document.documentElement.style.setProperty('--last-gradient', '#09143F');
      document.documentElement.style.setProperty('--linguagem-text', '#F55410');
      setGradient({ firstGradient: '#28206C', lastGradient: '#08133E' });
    }
    if (color === 'orange') {
      document.documentElement.style.setProperty('--header-color', '#E77D22');
      document.documentElement.style.setProperty('--first-gradient', '#E77D22');
      document.documentElement.style.setProperty('--last-gradient', '#EF3827');
      document.documentElement.style.setProperty('--linguagem-text', '#000000');
      setGradient({ firstGradient: '#E77D22', lastGradient: '#DF392D' });
    }
    if (color === 'yellow') {
      document.documentElement.style.setProperty('--header-color', '#F4D658');
      document.documentElement.style.setProperty('--first-gradient', '#F4D256');
      document.documentElement.style.setProperty('--last-gradient', '#E87E23');
      document.documentElement.style.setProperty('--linguagem-text', '#000000');
      setGradient({ firstGradient: '#F4D658', lastGradient: '#E77D22' });
    }
    setColorTheme(color);
  };

  const loadedContent = () => {
    return (
      <Layout
        state={state}
        theme={theme}
        setTheme={setTheme}
        gradient={gradient}
        colorTheme={colorTheme}
      >
        <SEO title="Home" />
        <Inicial state={state} theme={theme} setColorTheme={changeColorTheme} />
        <Mvv theme={theme} />
        <Portfolio projects={projects} theme={theme} />
        <Servicos theme={theme} />
        <Parceiros theme={theme} parceiros={parceiros} />
      </Layout>
    );
  };

  const getContent = () => {
    if (!loading) {
      return loadedContent();
    }

    return loadingFunc();
  };

  return getContent();
};

export default IndexPage;
