import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import useWindowDimensions from '../useWindowDimensions';
import { servicosData, servicosDataBlack } from './data';

const Box = ({ element, index }) => {
  const [content, setContent] = useState();
  const { height, width } = useWindowDimensions();

  const img = <img className={styles.img} src={element.img} alt=""></img>;
  const text = <div className={styles.boxText}>{element.description}</div>;

  useEffect(() => {
    setContent(img);
  }, []);

  const handleContent = enter => {
    if (enter) {
      setContent(text);
    } else {
      setContent(img);
    }
  };

  if (width < 720) {
    return <MobileBox element={element} index={index} />;
  }
  return (
    <div
      className={styles.box}
      onMouseEnter={() => {
        handleContent(true);
      }}
      onMouseLeave={() => {
        handleContent(false);
      }}
    >
      {content}
    </div>
  );
};

const MobileBox = ({ element, index }) => {
  const [clicked, setClicked] = useState(false);

  const getImage = () => {
    debugger;
    if (index !== undefined && clicked) {
      return (
        <img className={styles.img} src={servicosData[index].img} alt="" />
      );
    }
    return <img className={styles.img} src={element.img} alt="" />;
  };
  return (
    <div
      className={[
        styles.newBoxMobile,
        clicked ? styles.newBoxMobileClicked : styles.newBoxMobileNotClicked,
      ].join(' ')}
      onClick={() => setClicked(!clicked)}
    >
      <div
        className={[
          styles.newBoxImg,
          clicked ? styles.newBoxImgClicked : null,
        ].join(' ')}
      >
        {getImage()}
      </div>
      <div
        className={[
          styles.newBoxText,
          clicked ? styles.boxClicked : styles.boxNotClicked,
        ].join(' ')}
      >
        {element.description}
      </div>
    </div>
  );
};

export default Box;
