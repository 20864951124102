/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import TesteHeader from './teste-header';
import './layout.css';
import Footer from './footer';

const Layout = ({ state, children, theme, setTheme, gradient, colorTheme }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <TesteHeader
        state={state}
        siteTitle={data.site.siteMetadata.title}
        theme={theme}
        setTheme={setTheme}
        gradient={gradient}
        colorTheme={colorTheme}
      />
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{children}</main>
        <Footer colorTheme={colorTheme} />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
