import React from 'react';
import logo from '../../images/servicos/logo.svg';
import book from '../../images/servicos/book.svg';
import caixa from '../../images/servicos/caixa.svg';
import computer from '../../images/servicos/computador2.svg';
import pencil from '../../images/servicos/caneta2.svg';
import xicara from '../../images/servicos/xicara.png';
import logoBlack from '../../images/servicos/logoBlack.svg';
import bookBlack from '../../images/servicos/bookBlack.svg';
import caixaBlack from '../../images/servicos/caixaBlack.svg';
import computerBlack from '../../images/servicos/computerBlack.svg';
import pencilBlack from '../../images/servicos/pencilBlack.svg';
import xicaraBlack from '../../images/servicos/xicaraBlack.svg';

const servicosData = [
  {
    img: logo,
    description: (
      <span>
        Identidade Visual <br /> MVV e Essência
      </span>
    ),
  },
  {
    img: caixa,
    description: <span>Embalagens</span>,
  },
  {
    img: xicara,
    description: <span>Papelaria</span>,
  },
  {
    img: book,
    description: <span>Editorial</span>,
  },
  {
    img: computer,
    description: <span>Sites e Aplicativos</span>,
  },
  {
    img: pencil,
    description: <span>Ilustrações</span>,
  },
];

const servicosDataBlack = [
  {
    img: logoBlack,
    description: (
      <span>
        Identidade Visual <br /> MVV e Essência
      </span>
    ),
  },
  {
    img: caixaBlack,
    description: <span>Embalagens</span>,
  },
  {
    img: xicaraBlack,
    description: <span>Papelaria</span>,
  },
  {
    img: bookBlack,
    description: <span>Editorial</span>,
  },
  {
    img: computerBlack,
    description: <span>Sites e Aplicativos</span>,
  },
  {
    img: pencilBlack,
    description: <span>Ilustrações</span>,
  },
];

export { servicosData, servicosDataBlack };
