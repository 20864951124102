const mvvData = [
  {
    title: 'MISSÃO',
    description:
      'Desenvolver os aspectos humano, social, profissional e empresarial por meio da paixão, compartilhamento de conhecimento e capacitação. Fortalecendo assim, o design.',
  },
  {
    title: 'VISÃO',
    description:
      'Ser uma EJ referência em design com uma cultura organizacional e gestão consolidada.',
  },
  {
    title: 'VALORES',
    description:
      'Criatividade, paixão, versatilidade, profissionalismo, ética.',
  },
];

export default mvvData;
