import React from 'react';
import styles from './index.module.css';
import { servicosData, servicosDataBlack } from './data';
import Box from './box';

const Servicos = ({ theme }) => {
  const getServicos = () => {
    if (theme === 'light') {
      return (
        <div className={styles.content}>
          {servicosDataBlack.map((element, index) => (
            <Box key={element.img} element={element} index={index} />
          ))}
        </div>
      );
    } else {
      return (
        <div className={styles.content}>
          {servicosData.map(element => (
            <Box key={element.img} element={element} />
          ))}
        </div>
      );
    }
  };

  return (
    <section className={styles.servicos} id={'servicos'}>
      <h1 className="titleSection">SERVIÇOS</h1>
      {getServicos()}
    </section>
  );
};

export default Servicos;
