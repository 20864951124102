import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import enviroment from '../../services/enviroment';
import arrowLeftLaranja from '../../images/arrowLeftLaranja.svg';
import arrowRightLaranja from '../../images/arrowRightLaranja.svg';
import arrowLeft from '../../images/arrowLeft.svg';
import arrowRight from '../../images/arrowRight.svg';

import arrowLeftDark from '../../images/arrowLeftDark.svg';
import arrowRightDark from '../../images/arrowRightDark.svg';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const env = new enviroment();

const MobilePortfolio = ({ projects, theme }) => {
  const [selectedProject, setSelectedProject] = useState(0);
  const [selectedImg, setSelectedImg] = useState(0);

  useEffect(() => {
    console.log(projects);
  }, []);

  const updateCurrentSlide = index => {
    if (selectedImg !== index) {
      setSelectedImg(index);
    }
  };

  return (
    <section className={styles.mobilePortfolio} id={'portfolio'}>
      <h1 className={'titleSection'}>PORTFÓLIO</h1>
      <div className={styles.imageSelector}>
        {selectedImg > 0 ? (
          <img
            className={styles.arrowSelectorLeft}
            src={arrowLeftLaranja}
            onClick={() => {
              setSelectedImg(selectedImg - 1);
            }}
          ></img>
        ) : (
          <img
            className={styles.arrowSelectorLeftHidden}
            src={arrowLeftLaranja}
          />
        )}
        <Carousel
          autoPlay={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          interval={1e11}
          selectedItem={selectedImg}
          onChange={updateCurrentSlide}
        >
          {projects[selectedProject].imagens.map(item => {
            return (
              <div>
                <img src={env.APIUrl + '/' + item.img} />
              </div>
            );
          })}
        </Carousel>
        {selectedImg < projects[selectedProject].imagens.length - 1 ? (
          <img
            className={styles.arrowSelectorRight}
            src={arrowRightLaranja}
            onClick={() => {
              setSelectedImg(selectedImg + 1);
            }}
          ></img>
        ) : (
          <img
            className={styles.arrowSelectorRightHidden}
            src={arrowRightLaranja}
          />
        )}
      </div>
      <div className={styles.mobileTitle}>
        {projects[selectedProject].projeto.title}
      </div>
      <div className={styles.mobileDescription}>
        {projects[selectedProject].projeto.description}
      </div>
      <div className={styles.buttonsNext}>
        <div>
          {selectedProject > 0 ? (
            <button
              className={styles.buttonNext}
              onClick={() => {
                setSelectedImg(0);
                setSelectedProject(selectedProject - 1);
              }}
            >
              <img src={theme === 'dark' ? arrowLeft : arrowLeftDark}></img>
              <span className={styles.buttonText}>ANTERIOR</span>
            </button>
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {selectedProject < projects.length - 1 ? (
            <button
              className={styles.buttonNext}
              onClick={() => {
                setSelectedImg(0);
                setSelectedProject(selectedProject + 1);
              }}
            >
              <span className={styles.buttonText}>PRÓXIMO</span>
              <img src={theme === 'dark' ? arrowRight : arrowRightDark}></img>
            </button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <a
        href="https://www.behance.net/lamparinadesign"
        target="_blank"
        className={styles.linkVerMais}
      >
        <button className={styles.verMaisMobile}>VER MAIS</button>
      </a>
    </section>
  );
};

export default MobilePortfolio;
