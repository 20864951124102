import axios from 'axios';
import enviroment from './enviroment';

class Service {
  env = new enviroment();
  instance = axios.create({
    baseURL: this.env.APIUrl,
    timeout: 50000,
  });

  async getData() {
    return await this.instance.get('/projeto/');
  }
}

export default Service;
