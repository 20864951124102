import React, { useEffect } from 'react';
import styles from './index.module.css';
import enviroment from '../../services/enviroment';
import forward from '../../images/arrowRightLaranja.svg';
import back from '../../images/arrowLeftLaranja.svg';
const env = new enviroment();

const IMG_WIDTH = 799;
const IMG_HEIGHT = 533;

const PortfolioDetail = ({ selectedProject, unselectProject }) => {
  // useEffect(() => {
  //     console.log(selectedProject);
  // }, []);

  return (
    <div className={styles.portfolioDetail}>
      <div>
        <PortfolioImgs selectedProject={selectedProject}></PortfolioImgs>
      </div>
      <div className={styles.infoWrapper}>
        <h2 className={styles.detailTitle}>{selectedProject.projeto.title}</h2>
        <span className={styles.detailText}>
          {selectedProject.projeto.description}
        </span>
        <div className={styles.buttonsWrapper}>
          <a href="https://www.behance.net/lamparinadesign" target="_blank">
            <button className={styles.verMais}>VER MAIS</button>
          </a>
          <button className={styles.voltar} onClick={unselectProject}>
            VOLTAR
          </button>
        </div>
      </div>
    </div>
  );
};

class PortfolioImgs extends React.Component {
  wheelTimeout;
  transitionTimeout;
  lastTouch = 0;
  state = {
    imgs: this.props.selectedProject.imagens,
    currentIndex: 0,
    movement: 0,
    transitionDuration: '0s',
  };

  componentWillUnmount = () => {
    clearTimeout(this.transitionTimeout);
  };

  handleTouchStart = e => {
    this.lastTouch = e.nativeEvent.touches[0].clientX;
  };

  handleTouchMove = e => {
    const delta = this.lastTouch - e.nativeEvent.touches[0].clientX;
    this.lastTouch = e.nativeEvent.touches[0].clientX;

    this.handleMovement(delta);
  };

  handleTouchEnd = () => {
    this.handleMovementEnd();
    this.lastTouch = 0;
  };

  handleWheel = e => {
    clearTimeout(this.wheelTimeout);
    this.handleMovement(e.deltaX);
    this.wheelTimeout = setTimeout(() => this.handleMovementEnd(), 100);
  };

  handleMovement = delta => {
    clearTimeout(this.transitionTimeout);

    this.setState(state => {
      const maxLength = state.imgs.length - 1;

      let nextMovement = state.movement + delta;

      if (nextMovement < 0) {
        nextMovement = 0;
      }

      if (nextMovement > maxLength * IMG_WIDTH) {
        nextMovement = maxLength * IMG_WIDTH;
      }

      return {
        movement: nextMovement,
        transitionDuration: '0s',
      };
    });
  };

  handleMovementEnd = () => {
    const { movement, currentIndex } = this.state;

    const endPosition = movement / IMG_WIDTH;
    const endPartial = endPosition % 1;
    const endingIndex = endPosition - endPartial;
    const deltaInteger = endingIndex - currentIndex;

    let nextIndex = endingIndex;

    if (deltaInteger >= 0) {
      if (endPartial >= 0.1) {
        nextIndex += 1;
      }
    } else if (deltaInteger < 0) {
      nextIndex = currentIndex - Math.abs(deltaInteger);
      if (endPartial > 0.9) {
        nextIndex += 1;
      }
    }

    this.transitionTo(nextIndex, Math.min(0.5, 1 - Math.abs(endPartial)));
  };

  transitionTo = (index, duration) => {
    this.setState({
      currentIndex: index,
      movement: index * IMG_WIDTH,
      transitionDuration: `${duration}s`,
    });

    this.transitionTimeout = setTimeout(() => {
      this.setState({ transitionDuration: '0s' });
    }, duration * 100);
  };

  render() {
    const { currentIndex, movement, transitionDuration, imgs } = this.state;
    const maxLength = imgs.length - 1;
    const maxMovement = maxLength * IMG_WIDTH;

    return (
      <div className={styles.App}>
        <div
          className={styles.main}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          onWheel={this.handleWheel}
        >
          <div
            className={styles.swiper}
            style={{
              transform: `translateX(${movement * -1}px)`,
              transitionDuration: transitionDuration,
            }}
          >
            {imgs.map(src => {
              return (
                <div>
                  <img
                    key={src}
                    className={styles.detailImage}
                    src={env.APIUrl + '/' + src.img}
                    alt={this.props.selectedProject.title}
                  />
                </div>
              );
            })}
          </div>
          {movement !== 0 && (
            <button
              className={[styles.back, styles.move].join(' ')}
              onClick={() => {
                this.transitionTo(currentIndex - 1, 0.5);
              }}
            >
              <img src={back}></img>
            </button>
          )}
          {movement !== maxMovement && (
            <button
              className={[styles.next, styles.move].join(' ')}
              onClick={() => {
                this.transitionTo(currentIndex + 1, 0.5);
              }}
            >
              <img src={forward}></img>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default PortfolioDetail;
