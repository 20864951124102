import React from 'react';
import styles from './index.module.css';
import caminho from '../../images/footer/caminho.svg';
import email from '../../images/footer/email.svg';
import facebook from '../../images/footer/facebook.svg';
import onda from '../../images/footer/footer-onda.svg';
import ondaLaranja from '../../images/onda-footer-laranja.svg';
import ondaAzul from '../../images/onda-footer-azul.svg';
import ondaAmarelo from '../../images/onda-footer-amarelo.svg';
import instagram from '../../images/footer/instagram.svg';
import places from '../../images/footer/places.svg';

import logo from '../../images/logo.svg';
import logo_dark from '../../images/logo_dark.svg';

import useWindowDimensions from '../useWindowDimensions';

const Footer = ({ colorTheme }) => {
  const { height, width } = useWindowDimensions();

  const getImgByColorTheme = () => {
    if (colorTheme === 'blue')
      return <img style={{ width: '100%' }} src={ondaAzul}></img>;
    if (colorTheme === 'yellow')
      return <img style={{ width: '100%' }} src={ondaAmarelo}></img>;
    return <img style={{ width: '100%' }} src={ondaLaranja}></img>;
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSdi9pTCA4iQHxbmha1qAf73eG6lbk4rtMIj0wVwM35lDjKufA/viewform',
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <footer id={'footer'}>
      <div className={styles.footer}>
        <div className={styles.content}>
          <div className={styles.wrapperPodemosAjudar}>
            <h2 className={styles.title}>PODEMOS AJUDAR?</h2>
            <button
              className={styles.faleConosco}
              onClick={() => openInNewTab()}
            >
              FALE CONOSCO
            </button>
          </div>
          <div className={styles.grapper}>
            <div className={styles.divider}></div>
            <div className={styles.icons}>
              <div className={styles.options}>
                <a
                  href="https://www.google.com/maps/place/Lamparina+Design/@-15.7624463,-47.8723895,17z/data=!3m1!4b1!4m5!3m4!1s0x935a3bb98486ad8d:0xfccfdbef292aa293!8m2!3d-15.7624463!4d-47.8702008"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className={styles.icon} src={places} />
                </a>
                <a
                  href="https://www.instagram.com/lamparinadesign"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className={styles.icon} src={instagram} />
                </a>
                <a target="_blank">
                  <img className={styles.icon} src={email} />
                </a>
                <a
                  href="https://www.facebook.com/lamparinadesign/"
                  target="_blank"
                >
                  <img className={styles.icon} src={facebook} />
                </a>
                <a
                  href="https://www.behance.net/lamparinadesign"
                  target="_blank"
                >
                  <img className={styles.icon} src={caminho} />
                </a>
              </div>
              <img className={styles.logo} src={logo} alt="logo"></img>
            </div>
          </div>
        </div>
        <div className={styles.background}>
          <div className={styles.onda}>{getImgByColorTheme()}</div>
          {/* <img className={styles.onda} src={onda} alt="onda"></img> */}
          <div className={styles.expandedGradient}></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
