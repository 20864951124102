import React, { useState } from 'react';
import styles from './index.module.css';
import themeBlack from '../../images/themeBlack.svg';
import themeWhite from '../../images/themeWhite.svg';

const Inicial = ({ state, theme, setColorTheme }) => {
  const [isPencilActive, setIsPencilActive] = useState(false);

  const toggleColorDivs = () => {
    const div = document.getElementsByClassName(styles.themeColorsDiv)[0];
    // console.log(document.getElementsByClassName(styles.themeColorsDiv)[0].style)
    if (div.style.opacity === '1') {
      div.style.opacity = '0';
      div.style.visibility = 'hidden';
      setIsPencilActive(false);
    } else {
      div.style.opacity = '1';
      div.style.visibility = 'visible';
      setIsPencilActive(true);
    }
  };

  const hideColorDivs = () => {
    const div = document.getElementsByClassName(styles.themeColorsDiv)[0];
    // console.log(document.getElementsByClassName(styles.themeColorsDiv)[0].style)
    div.style.opacity = '0';
    div.style.visibility = 'hidden';
  };

  return (
    <section className={styles.secaoInicial} style={{ height: '60vh' }}>
      <div
        id={'themeInicial'}
        className={[
          styles.title,
          // state.header === 1 ? styles.animateDownMiddle : undefined,
          // state.header === 2 ? styles.animateUp : undefined,
        ].join(' ')}
      >
        <h1 className={styles.inline}>
          Olhamos{' '}
          <span className={[styles.tendencias, styles.inline].join(' ')}>
            além das tendências
          </span>{' '}
          para ajudar você a se comunicar da melhor maneira!
        </h1>
        <span className={styles.content}>
          Somos uma Empresa Júnior da UnB formada por estudantes <br />
          apaixonados pela profissão, apoiados por professores e inspirados{' '}
          <br />
          por ajudar pessoas!
        </span>
      </div>
      <div className={styles.themeSelector}>
        <div
          className={styles.themeColorsDiv}
          onClick={() => {
            hideColorDivs();
            setIsPencilActive(false);
          }}
        >
          <div
            className={styles.blue}
            onClick={() => {
              setColorTheme('blue');
            }}
          ></div>
          <div
            className={styles.red}
            onClick={() => {
              setColorTheme('orange');
            }}
          ></div>
          <div
            className={styles.yellow}
            onClick={() => {
              setColorTheme('yellow');
            }}
          ></div>
        </div>
        <div
          className={[
            styles.pencil,
            isPencilActive
              ? theme === 'dark'
                ? styles.themeActiveDark
                : styles.themeActive
              : styles.themeUnactive,
            theme === 'dark' ? styles.borderWhite : styles.borderBlack,
          ].join(' ')}
          onClick={() => {
            toggleColorDivs();
          }}
        >
          <img
            className={styles.pencilIcon}
            src={theme === 'dark' ? themeWhite : themeBlack}
          ></img>
        </div>
      </div>
    </section>
  );
};

export default Inicial;
