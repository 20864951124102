import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import logo from '../../images/logo.svg';
import logoBlack from '../../images/logo_dark.svg';
// import logoWhite from '../../images/logoWhite.png';
import moon from '../../images/inicial/moon.png';
import sun from '../../images/inicial/sun.png';
// import onda from '../../images/inicial/onda.svg';
import { tween } from 'popmotion';
import { interpolate } from 'flubber';
import posed from 'react-pose';
import useWindowDimensions from '../useWindowDimensions';
import menu from '../../images/menu.svg';
// import arrowRight from '../../images/arrowRight.svg';
import arrowRightLaranja from '../../images/arrowRightLaranja.svg';

const paths = {
  ondaInicial:
    'M0 788.775V0H1920V726C1447 980 1054.5 912.5 725.031 788.775C395.563 665.051 159.665 701.3 0 788.775Z',
  ondaIntermediario:
    'M0 218.488V0H1920V201.1C1567 345 1129.5 304.5 725.031 218.488C320.563 132.476 159.665 194.258 0 218.488Z',
  ondaIntermediario2:
    'M0 218.488V0H1920V201.1C1567 345 1129.5 304.5 725.031 218.488C320.563 132.476 159.665 194.258 0 218.488Z',
};

const paths540 = {
  ondaInicial:
    'M0 487V0H540V465.493C436.5 518.5 332.5 513.5 239.25 487C146 460.5 89.5 457 0 487Z',
  ondaIntermediario:
    'M0 218.488V0H1920V201.1C1567 345 1129.5 304.5 725.031 218.488C320.563 132.476 159.665 194.258 0 218.488Z',
  ondaIntermediario2:
    'M0 218.488V0H1920V201.1C1567 345 1129.5 304.5 725.031 218.488C320.563 132.476 159.665 194.258 0 218.488Z',
};

const pathIds = Object.keys(paths);

const morphTransition = ({ from, to }) =>
  tween({
    from: 0,
    to: 1,
    duration: 500,
  }).pipe(interpolate(from, to));

const Icon = posed.path(
  pathIds.reduce((config, id) => {
    config[id] = {
      d: paths[id],
      transition: morphTransition,
    };

    return config;
  }, {})
);

const Icon540 = posed.path(
  pathIds.reduce((config, id) => {
    config[id] = {
      d: paths540[id],
      transition: morphTransition,
    };

    return config;
  }, {})
);

const viewBoxHeight = {
  '0': '0 0 1920 900',
  '1': '0 0 1920 900',
  '2': '0 0 1920 300',
};

const viewBoxHeight540 = {
  '0': '0 0 540 507',
  '1': '0 0 1920 900',
  '2': '0 0 1920 300',
};

const Header = ({ state, theme, setTheme, gradient }) => {
  const [pathIndex, setPathIndex] = useState(0);
  const [rectangleHeight, setRectangleHeight] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const { height, width } = useWindowDimensions();

  const gotoNext = () => {
    const nextIndex = pathIndex + 1;
    setPathIndex(nextIndex > pathIds.length - 1 ? 0 : nextIndex);
  };

  const findOndaHeight = () => (width * 900) / 1920;

  const findRectangleHeight = () => 0.8 * height - findOndaHeight();

  const changeTheme = () => {
    if (theme == 'dark') {
      document.documentElement.style.setProperty('--main-bg-color', '#F8F8F8');
      document.documentElement.style.setProperty('--text-color', 'black');
      document.documentElement.style.setProperty('--box-color', '#EDEDED');
      document.documentElement.style.setProperty('--mvv-background', '#EDEDED');
      document.documentElement.style.setProperty('--mvv-text', '#D3D3D3');

      setTheme('light');
    } else {
      document.documentElement.style.setProperty('--main-bg-color', '#050c27');
      document.documentElement.style.setProperty('--text-color', 'white');
      document.documentElement.style.setProperty('--box-color', '#1e243d');
      document.documentElement.style.setProperty(
        '--mvv-background',
        'rgba(255, 255, 255, 0.1)'
      );
      document.documentElement.style.setProperty('--mvv-text', '#050C27');

      setTheme('dark');
    }
  };

  useEffect(() => {
    const height = findRectangleHeight();
    if (height !== rectangleHeight) {
      setRectangleHeight(findRectangleHeight);
    }
  }, [height, width]);

  return (
    <div className={styles.header}>
      <div className={styles.imgContainer}>
        <div className={styles.text}>
          <div className={styles.topHeader}>
            <div className={styles.logo}>
              <img src={logo} alt="logo"></img>
            </div>
            <div>
              <ul className={styles.options}>
                <li>
                  <a
                    className={styles.headerOption}
                    onClick={() => window.location.replace('/#portfolio')}
                  >
                    PORTFÓLIO
                  </a>
                </li>
                <li>
                  <a
                    className={styles.headerOption}
                    onClick={() => window.location.replace('/#servicos')}
                  >
                    SERVIÇOS
                  </a>
                </li>
                <li>
                  <a
                    className={styles.headerOption}
                    onClick={() => window.location.replace('/#parceiros')}
                  >
                    PARCEIROS
                  </a>
                </li>
                <li>
                  <a
                    className={styles.headerOption}
                    onClick={() => window.location.replace('/#footer')}
                  >
                    FALE CONOSCO
                  </a>
                </li>
                <li className={styles.darkWrapper}>
                  {theme === 'dark' ? (
                    <img
                      className={styles.darkThemeSelector}
                      src={moon}
                      onClick={changeTheme}
                    ></img>
                  ) : (
                    <img
                      className={styles.darkThemeSelector}
                      src={sun}
                      onClick={changeTheme}
                    ></img>
                  )}
                </li>
              </ul>
              <div className={styles.iconMenuMobile}>
                <li className={styles.darkWrapperMobile}>
                  {theme === 'dark' ? (
                    <img
                      className={styles.darkThemeSelector}
                      src={moon}
                      onClick={changeTheme}
                    ></img>
                  ) : (
                    <img
                      className={styles.darkThemeSelector}
                      src={sun}
                      onClick={changeTheme}
                    ></img>
                  )}
                </li>
                <img
                  className={styles.menu}
                  src={menu}
                  alt="menu"
                  onClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                ></img>
              </div>
            </div>
          </div>
          <div className={state.header !== 0 ? styles.animateUp : undefined}>
            <div className={styles.title}>
              <h1>O bom design é&nbsp;</h1>
              <h1 className={styles.linguagem}>linguagem</h1>
              <h1>,</h1>
            </div>
            <div className={styles.title}>
              <h1>além do&nbsp;</h1>
              <h1>estilo</h1>
            </div>
          </div>
        </div>
        <div>
          {/* <div className={styles.expandedGradient} style={{ height: `  ${rectangleHeight}px` }}>
                    </div> */}
          {/* <img src={onda}></img> */}
          {width > 0 ? (
            <svg
              className={styles.ondaInicial}
              width={width}
              viewBox={viewBoxHeight[state.header]}
              fill="url(#paint0_linear)"
            >
              <Icon pose={pathIds[state.header]} />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="-1.94914e-05"
                  y1="450"
                  x2="1920"
                  y2="450"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor={gradient.lastGradient} />
                  <stop offset="1" stopColor={gradient.firstGradient} />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg
              className={styles.ondaInicial}
              width={width}
              viewBox={viewBoxHeight540[state.header]}
              fill="url(#paint0_linear)"
            >
              <Icon pose={paths540[state.header]} />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="-1.94914e-05"
                  y1="450"
                  x2="1920"
                  y2="450"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor={gradient.lastGradient} />
                  <stop offset="1" stopColor={gradient.firstGradient} />
                </linearGradient>
              </defs>
            </svg>
          )}
        </div>
      </div>
      {/* Deixar o fechamento no topo com bubbling */}
      {
        <div
          onClick={() => setOpenMenu(false)}
          className={styles.menuContainer}
          style={{
            marginRight: openMenu ? '0' : '-100vw',
            backgroundColor: theme === 'light' ? '#EDEDED' : '#1E243D',
          }}
        >
          {theme === 'light' ? (
            <div className={styles.logoHeader}>
              <img
                src={logoBlack}
                alt="logo"
                style={{ height: '4.5rem' }}
              ></img>
            </div>
          ) : (
            <div className={styles.logoHeader}>
              <img src={logo} alt="logo" style={{ height: '4.5rem' }}></img>
            </div>
          )}

          <ul>
            <li>
              <img className={styles.headerIcon} src={arrowRightLaranja} />
              <a
                className={styles.headerOption}
                onClick={() => window.location.replace('/#portfolio')}
              >
                PORTFÓLIO
              </a>
            </li>
            <li>
              <img className={styles.headerIcon} src={arrowRightLaranja} />
              <a
                className={styles.headerOption}
                onClick={() => window.location.replace('/#servicos')}
              >
                SERVIÇOS
              </a>
            </li>
            <li>
              <img className={styles.headerIcon} src={arrowRightLaranja} />
              <a
                className={styles.headerOption}
                onClick={() => window.location.replace('/#parceiros')}
              >
                PARCEIROS
              </a>
            </li>
            <li>
              <img className={styles.headerIcon} src={arrowRightLaranja} />
              <a
                className={styles.headerOption}
                onClick={() => window.location.replace('/#footer')}
              >
                FALE CONOSCO
              </a>
            </li>
          </ul>
          <div></div>
        </div>
      }
    </div>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
