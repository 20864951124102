import React from 'react';
import styles from './index.module.css';
import selectedOption from '../../images/portfolio/selectedOption.svg';

const data = [
  'Branding',
  'Embalagem',
  'Papelaria',
  'Editorial',
  'Aplicativo e Site',
  'Ilustração',
];

const PortfolioOptions = ({ onClick, selected }) => {
  return (
    <div className={styles.portfolioOptions}>
      {data.map((element, index) => (
        <Option
          onClick={onClick}
          last={index === data.length - 1}
          name={element}
          selected={selected === element}
        />
      ))}
    </div>
  );
};

const Option = ({ onClick, last, name, selected }) => {
  return (
    <div
      className={styles.option}
      onClick={() => {
        onClick(name);
      }}
    >
      <div className={styles.comboOptions}>
        <span
          className={selected ? styles.selectedText : styles.notSelectedText}
        >
          &nbsp;{name}&nbsp;
        </span>
        <div
          className={selected ? styles.selectedImg : styles.notSelectedImg}
        ></div>
      </div>
      {!last && '|'}
    </div>
  );
};

export default PortfolioOptions;
