import React from 'react';
import styles from './index.module.css';
import enviroment from '../../services/enviroment';

const PortfolioList = ({ projects, selectProject, selectedOption }) => {
  const env = new enviroment();
  // const { height, width } = useWindowDimensions();

  return (
    <div className={styles.portfolioList}>
      {projects.map(project => {
        let TAG;
        try {
          TAG = project.projeto.sections[0];
        } catch (error) {
          // Não há TAG pra esse projeto
          TAG = '';
        }
        return (
          <div className={styles.flipCard}>
            <div
              className={[
                styles.flipCardInner,
                selectedOption && selectedOption !== TAG ? styles.flip : null,
              ].join(' ')}
            >
              <div className={styles.flipCardFront}>
                <img
                  onClick={() => {
                    selectProject(project);
                  }}
                  className={[styles.image].join(' ')}
                  src={env.APIUrl + '/' + project.imagens[0].img}
                />
              </div>
              <div className={styles.flipCardBack}>
                <div className={styles.cardBackText}>
                  {project.projeto.title}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PortfolioList;
