import React from 'react';
import styles from './index.module.css';
import enviroment from '../../services/enviroment';

import logoUnb from '../../images/parceiros/unb.svg';
import logoUnbDark from '../../images/parceiros/unb_dark.svg';

import brasilJunior from '../../images/parceiros/brasil_junior.svg';
import brasilJuniorDark from '../../images/parceiros/brasil_junior_dark.svg';

import cjr from '../../images/parceiros/cjr.svg';
import cjrDark from '../../images/parceiros/cjr_dark.svg';

import concentro from '../../images/parceiros/concentro.svg';
import concentroDark from '../../images/parceiros/concentro_dark.svg';
// import parceirosData from './data';

export default class Parceiros extends React.Component {
  theme;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      parceirosData: props.parceiros,
    };
    this.env = new enviroment();
    this.theme = props.theme;
  }

  // componentDidMount() {
  //   fetch(this.env.APIUrl)
  //     .then(res => res.json())
  //     .then(
  //       (result) => {
  //         this.setState({
  //           isLoaded: true,
  //           parceirosData: result.parceiros
  //         });
  //       },
  //       // Nota: É importante lidar com os erros aqui
  //       // em vez de um bloco catch() para não recebermos
  //       // exceções de erros dos componentes.
  //       (error) => {
  //         this.setState({
  //           isLoaded: true,
  //           error
  //         });
  //       }
  //     )
  // }

  render() {
    const { parceirosData } = this.state;
    return (
      // <section className={styles.title} id={'parceiros'}>
      //   <h1 className="titleSection">PARCEIROS</h1>
      //   <div className={styles.parceiros}>
      //     {parceirosData.map(element => {
      //       return element.isActive ? (
      //         <img
      //           key={element.id}
      //           className={styles.parceiro}
      //           src={this.env.APIUrl + `${element.img}`}
      //           alt={element.alt}
      //         ></img>
      //       ) : (
      //         void 0
      //       );
      //     })}
      //   </div>
      // </section>

      <section className={styles.title} id={'parceiros'}>
        <h1 className="titleSection">PARCEIROS</h1>
        <div className={styles.parceiros}>
          {/* Logo Unb */}
          <img
            // key={element.id}
            className={styles.parceiro}
            src={this.props.theme === 'dark' ? logoUnb : logoUnbDark}
            alt={'Logo Universidade de Brasília'}
          ></img>
          <img
            // key={element.id}
            className={styles.parceiro}
            src={this.props.theme === 'dark' ? cjr : cjrDark}
            alt={'Logo CJR'}
          ></img>
          <img
            // key={element.id}
            className={styles.parceiro}
            src={this.props.theme === 'dark' ? concentro : concentroDark}
            alt={'Logo concentro'}
          ></img>
          <img
            // key={element.id}
            className={styles.parceiro}
            src={this.props.theme === 'dark' ? brasilJunior : brasilJuniorDark}
            alt={'Logo Brasil junior'}
          ></img>
          {/* {parceirosData.map(element => {
            return element.isActive ? (
              <img
                key={element.id}
                className={styles.parceiro}
                src={this.env.APIUrl + `${element.img}`}
                alt={element.alt}
              ></img>
            ) : (
              void 0
            );
          })} */}
        </div>
      </section>
    );
  }
}
